#rootSidebar {
    display: flex;
    padding-top: 0px;
}

#NavContinerApp {
    position: absolute;
    left: 0px;
}

#sideBarbgc {
    background-image: url(../images/side.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #eeeeee;
    width: 240px;
}

/* #sideBarbgc1 {
    background-image: url(../images/side1.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #054cb0;
    width: 240px;
}

#sideBarbgc2 {
    background-image: url(../images/Office.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #054cb0;
    width: 240px;
} */
#drawerSidebar {
    padding-top: 0;
}

#BTNSlideContiner {
    padding-top: 20px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#menuButtonSidebar {
    display: 'none';
}

#BTNSlide {
    width: 200px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    color: #fff;
    border-radius: 20px;
    background-color: #2980B9;

    
    cursor: pointer;
}

#BTNSlide:hover {
    transform: scale(1.04);
    background-color: #fff;
    color: #1AB2CD !important;
    transition-duration: .3s;
    transition-timing-function: linear;
}

#BTNSlide:active {
    transform: scale(1);
    background-color: #fff;
    color: #1AB2CD !important;
    transition-duration: .3s;
    transition-timing-function: linear;
}
.ActiveSliderBTN div{
    border: solid 2px #fff;
}
#BTNSlideName {
    margin-right: 15px;
    margin-left: 15px;
    font-size: 18px;
}


#drawerPaper {
    width: 240px;
}

#contentSidebar {
    flex-grow: 1;
    /* padding-top: 50px; */
}

@media (max-width: 480px) {
    #drawerSidebar {
        width: 240px;
        flex-shrink: 0;
    }

    #BTNSlideContiner {
        padding-top: 50;
        height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    #menuButtonSidebar {
        margin-right: 50px;
        z-index: 11;
    }
}