
#ContinerInPut {
  display: flex;
  justify-content: flex-start;
  margin: 20px 30px;

}

#navBac{
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #2980b9;
  padding: 0px 30px;
  color: #fff;
}
#InputTExtDash111 {
  width: 260px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 20px;
  /* background-color: #fafafa; */
}

#cardGallCOL{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

#ContinerInPut label{
  height: 40px;
  display: flex;
  align-items: center;
  width: 150px;
}
#InputTExtDash111Image{
  width: 260px;
  height: 33px;
  border: 0px;
 border-radius: 5px;
}
#BtnAddUser{
cursor: pointer;
margin: 30px;
border-radius: 10px;
}
#btnAddContiner{
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginContiner {
  width: 100%;
  height: 100vh;
  background-image: url(./assets/images/bglogin.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginContiner1 {
  width: 350px;
  height: 500px;
  background-color: rgba(85, 99, 103, 0.9);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.logoLogin {
  width: 200px;
  height: auto;
}

#inputNewLogin {
  width: 260px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  text-align: center;
  border: none;
  margin-top: 10px;
}

#inputNewLoginContiner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#BtnNewLoginContiner {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#BtnNewLogin {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #5dade2;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
}

#BtnNewLogin:hover {
  transform: scale(1.04);
}

#BtnNewLogin:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mapDescPage {
  width: 100%;
  height: 300px;
  border-radius: 10px;
}

#titlesidesheet {
  height: 40px;
  width: 100%;
  background-color: #eee;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

}



#btnAddContiner {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
}

#btnInfoOffice1 {
  height: 40px;
  width: 100px;
  border-radius: 10px;
  background-color: #b9512a;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#btnInfoOffice1:hover,
.btnUpTableSelect:hover,
#AddClientDeptBTN:hover,
#btnPrindPdf:hover,
.btnNAv:hover {
  transform: scale(1.04);
  transition-duration: .2s;
  transition-timing-function: linear;
}

#btnInfoOffice1:active,
.btnUpTableSelect:active,
#AddClientDeptBTN:active,
#btnPrindPdf:active,
.btnNAv:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}



#AddClientDeptBTN {
  width: 200px;
  height: 40px;
  background-color: #5dade2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin-top: 25px;
}
#infoShipPdfPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#textDescPagecontent{
width: 100%;
overflow: hidden;
white-space: nowrap;
text-align: center;
}
#AppBarPageContiner {
  height: 60px;
  width: 100%;
  background-color: #aaa;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
#textDescPageContinerAdmin2 {
  width: 96%;
  margin: 20px 2%;
}
#headerpdfCOntiner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 220px;
  padding: 0px 4%;
}

#colbanner{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:25px 0;
}
#BannerContinerDash{
  width: 300px;
  position: relative;
  height: 100px;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
#imgBannerDash{
  width: 300px;
  height: 100px;
}
#deleteBannerDash{
  position: absolute;
  bottom: 10px;
  right: calc(50% - 140px);
  color: #E74C3C;
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  border-radius: 50%;
  cursor: pointer;
}
.navvBarContinerN{
  direction: rtl;
  width: 100%;
  min-height: 60px;
  background-color: #eee;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.TitlNav{
  display: flex;
  color: #fff;
}
.navvBarContiner a{
  color: #eee;
  text-decoration: none;
}